.app__experiences {
    flex: 1;
    width: 100%;
    flex-direction: column;
    // background-color: ;
}
.app__exp-item{
    flex-direction: column;
    display: flex;
    align-items: flex-start;
    background: var(--white-color);
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
    border-radius: 20px;
    padding:3rem;
    img{
        width: 200px;
        height: 100px;
        padding: 1rem;
    }

    @media screen and (max-width: 450px) {
        display: 100%;
        padding: 0.25rem;
    }

}
.app__exp-company-head-details{
    margin: 10px;
}
.app__exp_role{
    font-weight: 1000;
}
.app__exp-body{
    padding: 1rem;
    .p-text{
    font-size: 1rem ;
    }
}
                    
.app__exp_skills{
    margin-top: 1rem;
    display: flex;
    justify-items: center;
    align-items: center;
    /* gap: 1rem; */
    flex-wrap: wrap;
}
.app__skill-name{
    margin: 0.4rem;
    padding: 0.5rem;
    border-radius: 0.5rem;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);

    @media screen and (max-width: 450px){
        padding: 0.25rem;
        border-radius: 0.25rem;
    }
}

.app__exp-desc{
    max-height: 25vh ;
    overflow: hidden;
    text-overflow: ellipsis;
    transition: max-height 2s ease-in-out;
    &:hover{
    max-height: 100vh;
    }
}